exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-ceremonies-js": () => import("./../../../src/pages/ceremonies.js" /* webpackChunkName: "component---src-pages-ceremonies-js" */),
  "component---src-pages-chanqi-js": () => import("./../../../src/pages/chanqi.js" /* webpackChunkName: "component---src-pages-chanqi-js" */),
  "component---src-pages-china-index-js": () => import("./../../../src/pages/china-index.js" /* webpackChunkName: "component---src-pages-china-index-js" */),
  "component---src-pages-contribution-js": () => import("./../../../src/pages/contribution.js" /* webpackChunkName: "component---src-pages-contribution-js" */),
  "component---src-pages-dharmaglimpse-js": () => import("./../../../src/pages/dharmaglimpse.js" /* webpackChunkName: "component---src-pages-dharmaglimpse-js" */),
  "component---src-pages-foqi-js": () => import("./../../../src/pages/foqi.js" /* webpackChunkName: "component---src-pages-foqi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korea-trip-schedule-js": () => import("./../../../src/pages/koreaTripSchedule.js" /* webpackChunkName: "component---src-pages-korea-trip-schedule-js" */),
  "component---src-pages-lecture-js": () => import("./../../../src/pages/lecture.js" /* webpackChunkName: "component---src-pages-lecture-js" */),
  "component---src-pages-livestream-js": () => import("./../../../src/pages/livestream.js" /* webpackChunkName: "component---src-pages-livestream-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-master-js": () => import("./../../../src/pages/master.js" /* webpackChunkName: "component---src-pages-master-js" */),
  "component---src-pages-new-contribution-js": () => import("./../../../src/pages/newContribution.js" /* webpackChunkName: "component---src-pages-new-contribution-js" */),
  "component---src-pages-online-class-js": () => import("./../../../src/pages/onlineClass.js" /* webpackChunkName: "component---src-pages-online-class-js" */),
  "component---src-pages-practices-js": () => import("./../../../src/pages/practices.js" /* webpackChunkName: "component---src-pages-practices-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-relics-js": () => import("./../../../src/pages/relics.js" /* webpackChunkName: "component---src-pages-relics-js" */),
  "component---src-pages-statement-of-faith-js": () => import("./../../../src/pages/statement-of-faith.js" /* webpackChunkName: "component---src-pages-statement-of-faith-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-upcoming-events-js": () => import("./../../../src/pages/upcoming-events.js" /* webpackChunkName: "component---src-pages-upcoming-events-js" */)
}

